























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { SchoolYear } from "@/models/SchoolYear";
import { AttributeVO } from "@/models/vos/AttributeVO";
import PropertyArea from "@/views/components/common/PropertyArea.vue";
import { Class } from "@/models/Class";
import IAppBar from "@/views/components/common/toolbar/IAppBar.vue";
import classSubjectPartReportsByStudentAndSchoolYear from "@/graphql/classSubjectPartReport/classSubjectPartReportsByStudentAndSchoolYear.graphql";
import studentSubjectsOfStudentInSchoolYear from "@/graphql/studentReport/studentSubjectsOfStudentInSchoolYear.graphql";
import classAssignmentsOfStudent from "@/graphql/classes/classAssignmentsOfStudent.graphql";
import ClassSubjectPartReport from "@/models/ClassSubjectPartReport";
import StudentReport from "@/models/StudentReport";
import SchoolSubjectAssignment from "@/models/SchoolSubjectAssignment";
import ClassSubjectReportTable from "@/views/components/classSubjectPartReport/ClassSubjectReportTable.vue";
import ClassStudent from "@/models/ClassStudent";
import { getModule } from "vuex-module-decorators";
import PermissionsModule from "@/modules/PermissionsModule";
import SchoolSubjectAssignmentTable from "@/views/components/schoolYear/SchoolSubjectAssignmentTable.vue";

@Component({
  components: {
    SchoolSubjectAssignmentTable,
    ClassSubjectReportTable,
    IAppBar,
    PropertyArea,
  },
  apollo: {
    reports: {
      query: classSubjectPartReportsByStudentAndSchoolYear,
      variables() {
        return {
          student_id: this.studentId,
          school_year_id: this.school_year.id,
        };
      },
      update: (data) => data.student?.classSubjectPartReports ?? [],
    },
    studentSubjects: {
      query: studentSubjectsOfStudentInSchoolYear,
      variables() {
        return {
          student_id: this.studentId,
          school_year_id: this.school_year.id,
        };
      },
      update: (data) => data.student?.schoolSubjectAssignments ?? [],
    },
    classAssignments: {
      query: classAssignmentsOfStudent,
      variables() {
        return {
          school_year_id: this.school_year.id,
          student_id: this.studentId,
        };
      },
      update: (data) => data.student?.classAssignments ?? [],
    },
  },
})
export default class SchoolYearShow extends Vue {
  @Prop({ required: true }) school_year!: SchoolYear;

  protected reports: ClassSubjectPartReport[] = [];
  protected studentSubjects: SchoolSubjectAssignment[] = [];
  protected classAssignments: ClassStudent[] = [];

  protected get title() {
    return `${this.$tc("schoolYear", 1)} ${
      this.school_year.denomination ?? ""
    }`;
  }

  protected get studentId() {
    return getModule(PermissionsModule).studentId;
  }

  protected get downloadSuffix() {
    return `${this.school_year.denomination}`;
  }

  protected get hasTwoClasses(): boolean {
    return this.classAssignments.length > 1;
  }

  protected get classFirstTerm(): Class | null {
    let assignment = this.classAssignments.find(
      (assignment) => assignment.term == 1
    );

    return assignment?.class ?? null;
  }

  protected get classSecondTerm(): Class | null {
    let assignment = this.classAssignments.find(
      (assignment) => assignment.term == 2
    );
    return assignment?.class ?? null;
  }

  protected get attributes(): AttributeVO[] {
    let attributes: AttributeVO[] = [];

    let classFirstTerm = this.classFirstTerm;
    let classSecondTerm = this.classSecondTerm;
    if (classFirstTerm) {
      attributes.push({
        title: this.$t("classInTerm", { term: 1 }),
        value: classFirstTerm?.denomination ?? "",
      });
    }
    if (classSecondTerm) {
      attributes.push({
        title: this.$t("classInTerm", { term: 2 }),
        value: classSecondTerm?.denomination ?? "",
      });
    }

    return attributes;
  }
}
