







import Vue from "vue";
import Component from "vue-class-component";
import { SchoolYear } from "@/models/SchoolYear";
import getSchoolYear from "@/graphql/schoolYears/getSchoolYear.graphql";
import IAppBar from "@/views/components/common/toolbar/IAppBar.vue";
import { Prop } from "vue-property-decorator";
import { INVALID_ID } from "@/models/Model";
import { getModule } from "vuex-module-decorators";
import PermissionsModule from "@/modules/PermissionsModule";
import { RouteName } from "@/enums/RouteName";
import SchoolYearShow from "@/views/components/schoolYear/SchoolYearShow.vue";

@Component<SchoolYearPage>({
  components: { SchoolYearShow, IAppBar },
  apollo: {
    schoolYear: {
      query: getSchoolYear,
      variables() {
        return {
          id: this.year_id,
        };
      },
      watchLoading(loading) {
        getModule(PermissionsModule).setIsLoading(loading);
      },
      error() {
        this.$router.push({ name: RouteName.ERROR_404 });
      },
    },
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
})
export default class SchoolYearPage extends Vue {
  @Prop({ default: INVALID_ID }) year_id!: string;
  protected schoolYear: SchoolYear | null = null;

  protected activated() {
    this.$apollo.queries.schoolYear.refresh();
  }

  protected get title() {
    return `${this.$tc("schoolYear", 1)} ${
      this.schoolYear?.denomination ?? ""
    }`;
  }
}
