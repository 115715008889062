














import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PageRefreshButton from "@/views/components/common/buttons/page/PageRefreshButton.vue";
import TYPES from "@/types";
import { lazyInject } from "@/inversify.config";
import IStudentDataService from "@/services/IStudentDataService";
import ISubjectService from "@/services/ISubjectService";
import ITable from "@/views/components/common/table/ITable.vue";
import { getRefreshButton } from "@/models/vos/ButtonVO";
import BooleanIcon from "@/views/components/common/BooleanIcon.vue";
import Student from "@/models/Student";
import SchoolSubjectAssignment from "@/models/SchoolSubjectAssignment";
import SchoolSubjectAssignmentItem from "@/models/vos/SchoolSubjectAssignmentItem";

@Component({
  components: {
    BooleanIcon,
    ITable,
    PageRefreshButton,
  },
})
export default class SchoolSubjectAssignmentTable extends Vue {
  @Prop() schoolYearId!: string;
  @Prop({ default: () => [] }) students!: Student[];
  @Prop({ default: () => [] })
  schoolSubjectAssignments!: SchoolSubjectAssignment[];
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: false }) showStudent!: boolean;
  @Prop({ default: false }) expandableTitle!: boolean;
  @Prop({ default: true }) createEnabled!: boolean;
  @Prop({ default: true }) editEnabled!: boolean;
  @Prop({ default: true }) deleteEnabled!: boolean;
  @lazyInject(TYPES.IStudentDataService) studentService!: IStudentDataService;
  @lazyInject(TYPES.ISubjectService) subjectService!: ISubjectService;

  protected get headers() {
    const headers: any[] = [];

    headers.push(
      {
        text: this.$tc("schoolSubject", 1),
        align: "start",
        sortable: true,
        value: "schoolSubject",
      },
      {
        text: this.$tc("schoolSubjectType", 1),
        align: "start",
        sortable: true,
        value: "schoolSubjectType",
      },
      {
        text: this.$t("firstTerm"),
        align: "start",
        sortable: true,
        value: "classSubjectFirstTerm",
      },
      {
        text: this.$t("secondTerm"),
        align: "start",
        sortable: true,
        value: "classSubjectSecondTerm",
      },
      {
        align: "end",
        sortable: false,
        value: "action",
      }
    );

    return headers;
  }

  protected get buttonVOs() {
    let buttons = [];

    buttons.push(getRefreshButton(this.loading));

    return buttons;
  }
  protected get schoolSubjectAssignmentItems(): SchoolSubjectAssignmentItem[] {
    return this.schoolSubjectAssignments.map((schoolSubjectAssignment) => {
      return {
        id: schoolSubjectAssignment.id,
        schoolSubject: schoolSubjectAssignment.schoolSubject.denomination,
        schoolSubjectType: this.$t(
          `schoolSubjectTypes.${schoolSubjectAssignment.schoolSubject.type}`
        ),
        classSubjectFirstTerm:
          schoolSubjectAssignment?.studentSubjectFirstTerm?.classSubject
            ?.full_denomination ?? "",
        classSubjectSecondTerm:
          schoolSubjectAssignment?.studentSubjectSecondTerm?.classSubject
            ?.full_denomination ?? "",
        student: schoolSubjectAssignment?.student?.full_name ?? "",
        schoolSubjectAssignment: schoolSubjectAssignment,
      };
    });
  }
}
