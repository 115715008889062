import { render, staticRenderFns } from "./SchoolSubjectAssignmentTable.vue?vue&type=template&id=1c0f1ed2&scoped=true&"
import script from "./SchoolSubjectAssignmentTable.vue?vue&type=script&lang=ts&"
export * from "./SchoolSubjectAssignmentTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c0f1ed2",
  null
  
)

export default component.exports